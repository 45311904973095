@import '../../constants/styles';

.circle-button {
  position: relative;
  width: 115px;
  height: 115px;
  border: 1px solid #FFFAE2;
  background: transparent;

  border-radius: 50%;
  padding: 0;
  position: relative;
  z-index: 20;
  transition: all .25s ease-in-out;
  overflow: hidden;

  &:hover {
    transform: scale(87%);

    &:before {
      transform: translateX(-50%) translateY(-50%) scale(113%);
    }
  }

  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 83%;
    height: 83%;
    background: #FFFAE2;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transition: all .25s ease-in-out;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
  }

  &.active {
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500px;
      height: 4px;
      z-index: 0;
      transform-origin: 0% 50%;
      animation: bullet 3s linear infinite forwards;
      background: $color-primary;
      box-shadow: 0 0 4px 8px $color-primary;
    }
  }
}

.outer {
  border: 1px solid #FFFAE2;
  border-radius: 50%;
  padding: 6%;
  width: 103px;
  height: 103px;
  transition: all .25s ease-in-out;

  // background: #00ff0080;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  &:disabled, &[disabled], &:disabled:hover {
    background-color: #cccccc;
    color: #666666;
  }

  &:hover {
    transform: scale(87%) translateX(-57.47%) translateY(-57.47%);

    .circle-button {
      transform: scale(114.9%);
    }
  }

  .circle-button {
    background: $color-secondary;

    &:hover {
      transform: scale(114.9%);
    }

    &:disabled, &[disabled], &:disabled:hover {
      background-color: #cccccc;
      color: #666666;
    }
  }


  .circle-button {
    overflow: hidden;
    width: 100%;
    height: 100%;

    &:after, &:before {
      display: none;
    }
  }

  .planet {
    opacity: 0;
    //background: #0f0;
    width: calc(50% + 5px);
    //margin-left: -20px;
    transition: opacity 0.5s linear;
    z-index: -1;
    &.active {
      opacity: 1;
    }

    &:after {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px darken($color-gold, 30%) solid;
      background: $color-secondary;
      z-index: -1;
      display: block;
      content: "";
    }

    position: absolute;
    left: -5px;
    top: calc(50% - 5px);
    //transform: translateY(-50%) ;
    transform-origin: 100% 50%;

    animation: spin 15s linear infinite;

    .active {
      opacity: 1;
    }
  }
}


@keyframes bullet {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
