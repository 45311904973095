@import '../../../constants/styles';

.forgot-error {
  text-align: center;
  width: 100%;
  color: $color-salmon;
  font-size: 15px;
  padding: 0 ;
}

.forgot-screen {
  width: 100%;
  // height: 100%;
  font-family: 'BeVietnam-Light', sans-serif;
  background-size: cover;
  width: 100%;
  background: url("../../../assets/images/new-bg.jpeg") no-repeat center;
  background-size: cover;
  
  a,
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .validation {
    margin-top: 0!important;
  }

  .confirm-code-inline {
    margin-bottom: 13px;
  }

  .email-confirm {

    padding: 30px;
    border-radius: 20px;
    background: transparentize($color: $color-secondary, $amount: 0.3);


    .status {
      margin-top: 30px;
      font-family: 'BeVietnam-Bold', sans-serif;
      animation: blink-animation 2s linear infinite;
      -webkit-animation: blink-animation 2s linear infinite;
    }

    // .status {
    //   font-family: BeVietnam-SemiBold, sans-serif;
    //   text-transform: capitalize;
    //   animation: blink-animation 2s linear infinite;
    //   -webkit-animation: blink-animation 2s linear infinite;
    // }

    // input[type="text"] {
    //   outline: 0;
    //   width: 100%;
    //   height: 60px;
    //   background: rgba(50, 57, 79, 0.7490196078);
    //   border: 1px solid #e5e5e580;
    //   border-radius: 5px;
    //   // padding-left: 32px;
    //   color: #FFFAE2;
    //   font-size: 18px;
    //   line-height: 26px;
    //   letter-spacing: 0.07em;
    //   text-align: center;

    //   input {
    //     padding-left: 50px;
    //     padding-bottom: 10px;
    //   }

    //   @media (max-height: 850px) {
    //     height: 48px;
    //     font-size: 14px;
    //   }

    //   &:disabled {
    //     opacity: 0.5;
    //   }

    //   &::placeholder {
    //     /* Chrome, Firefox, Opera, Safari 10.1+ */
    //     color: #FFFAE2;
    //     opacity: 1;
    //     /* Firefox */
    //   }
    // }

    .button {
      font-family: BeVietnam-Regular, sans-serif;
      letter-spacing: 0.07em;
      font-size: 16px;
      text-transform: uppercase;
      padding: 6px;

      &:disabled {
        opacity: 0.5;
      }

    }

    .confirm-code {
      margin: 15px 0;

      input[type="text"] {
        outline: 0;
        width: 100%;
        height: 60px;
        background: #fff;
        border: 1px solid rgba(50, 57, 79, 0.7490196078);
        font-family: BeVietnam-SemiBold, sans-serif;
        border-radius: 5px;
        color: rgba(50, 57, 79);
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.07em;
        text-align: center;
        // padding-left: 32px;

        @media (max-height: 850px) {
          height: 48px;
          font-size: 14px;
        }

        &:disabled {
          opacity: 0.5;
        }

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: rgba(50, 57, 79, 0.7490196078);
          font-family: BeVietnam-Light, sans-serif;
          opacity: 1;
          /* Firefox */
        }
      }
    }

    .send-again {
      .button {
        font-size: 12px;


        &:disabled {
          opacity: 0.5;
        }

      }
    }
  }


  .forgot-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      gap: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }


    .inline-logo {
      width: 45px;
      height: 45px;
    }

    //padding-top: 90px;

    color: #fff;

    h1 {
      color: #fff;

      // margin: 25px 0;

      @media (max-height: 720px) {
        // margin: 15px 0;
        font-size: 44px;
      }

    }

    h2 {
      max-width: 250px;
      font-size: 17px;
      line-height: 23px;
      font-family: 'BeVietnam-Medium', sans-serif;
    }
  }

  .forgot-content {
    border-radius: 15px;
    //flex: 1;
    padding-top: 14px;
  }

  .forgot-or-create {
    font-size: 16px;
    line-height: 23px;
    font-family: 'BeVietnam-Medium', sans-serif;
    margin-bottom: 37px;

    a {
      color: #31394F;
    }
  }

  .forgot-social {
    margin-bottom: 43px;

    .social-btn {
      padding: 0;
      background: none;
      border: none;
      outline: 0;
      margin-left: 18px;
      margin-right: 18px;
      border-radius: 100%;
    }
  }

  .forgot-form {
    margin-top: 30px;

    @media (max-height: 720px) {
      padding-top: 15px;
    }
  }

  .code-input {
    input {
      background: #1D1D1D;
      color: #fff;
      &::placeholder {
        color: #808080;
      }
    }
  }


  h4 {
    position: relative;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 50px;
    font-family: BeVietnam-Medium, sans-serif;

    @media (max-height: 720px) {
      margin-bottom: 15px;
    }

    &:before,
    &:after {
      content: '';
      width: 110px;
      height: 1px;
      background: rgba(49, 57, 79, 0.5);
      position: absolute;
      left: 0;
      top: 14px;
    }

    &:after {
      left: auto;
      right: 0;
    }
  }


  .label {
    color: #6A6A6A;
    margin-bottom: 12px;
    font-family: 'BeVietnam-Light', sans-serif;
    font-size: 15px;
    margin-bottom: 13px;
  }

  .input-wrapper {
    // max-width: 350px;
    // align-self: stretch;
    width: 100%;

    input {
      width: 100%;
      padding-left: 50px;
      // padding-bottom: 10px;
    }
  }




  .back-to-login {
    text-align: center;
    margin-bottom: 0;
    color: #ABABAB;
    margin-top: 25px;
    font-size: 15px;

    a {
      color: #fff;
      outline: 0;
      font-size: 15px;
      line-height: 22px;
      text-decoration: underline;
      text-transform: capitalize;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .forgot-form-submit {
    margin-top: 20px;

    .forgot-submit-btn {
      width: 97px;
      height: 97px;
      background: #FFFAE2;
      border-radius: 100%;
      border: none;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 97px;
        height: 97px;
        border-radius: 100%;
        border: 1px solid #FFFAE2;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(120%);
        transition: all .25s ease-in-out;
      }

      &:hover {
        &:before {
          transform: translateY(-50%) translateX(-50%) scale(107%);
        }
      }
    }

    .circle-button {

      @media (max-height: 720px) {
        width: 90px;
        height: 90px;
      }

      border: 1px solid #fff;

      &:before {
        background-color: #fff;
      }
    }

    .forgot-submit-btn:disabled {
      width: 97px;
      height: 97px;
      background: #a4a4a4;
      border-radius: 100%;
      border: none;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 97px;
        height: 97px;
        border-radius: 100%;
        border: 1px solid #a4a4a4;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(120%);
        transition: all .25s ease-in-out;
      }
    }
  }

}