.affiliate-screen {
  background: url(../assets/images/new-bg.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;

  .intro-logo {
    margin-left: 20px;
    width: 120px;
  }

  .sideblock.horizontal {

    .header {
      margin: 0;
    }

    .logo-container {

      background: url(../assets/images/intro-logo.png);
      background-size: cover;
      background-repeat: no-repeat;

      width: 125px;
      height: 60px;
      margin-left: 15px;



      @media only screen and (max-width: 720px) {
        width: 48px;

        overflow: hidden;

        .intro-logo {
          position: absolute;
          margin: 0;
        }
      }


    }


    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 70px;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background: rgba(0, 0, 0, 0.8);

    h1 {
      text-align: center;
      margin: 0;

      font-size: 24px;

      @media only screen and (max-width: 720px) {
        font-size: 18px;
      }
    }

  }

  .logout {
    position: relative;
    // top: 48px,
    right: 20px;
    z-index: 100;
    background: transparent;
    border: none;
    color: #fff;
    text-decoration: underline;
    font-size: 15px;
  }

  @media only screen and (max-width: 720px) {

    width: 100%;
    padding: 0 15px;
    overflow-y: scroll;
  }

  .content {


    position: relative;
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
    margin-top: 50px;
  }

  h1 {
    font-size: 2rem;
    color: #fff;
    margin: 0;
    margin-bottom: 2rem;
  }

  textarea {
    padding-top: 10px;
  }

  select {
    width: 100%;
    background: none;
    height: 100%;
    border: none;
    color: #fff;
    outline: none;

    font-family: 'BeVietnam-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      opacity: 0.7;
      /* Firefox */
    }

    // background: #f00;
  }

  .affiliate-list {
    min-width: 800px;

    @media only screen and (max-width: 720px) {
      min-width: unset;
      flex: 1;
      // align-self: stretch;
      width: 100%;
    }
  }

  .head {
    h1 {
      margin: 0;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @media only screen and (max-width: 720px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .button-wrapper {
      max-width: 250px;
    }
  }


  .affiliate-form {
    position: relative;
    background: #00000080;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 720px) {
      width: 100%;
      margin-top: 50px;
      align-self: flex-start;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
    }

    .button {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .multiline {
      height: 100px;
    }

    .input-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 35px;
      gap: 20px;

      @media only screen and (max-width: 720px) {
        flex-direction: column;
        gap: 30px;

        input,
        select {
          padding-top: 6px;
          padding-bottom: 6px;
          font-size: 16px;
        }
      }

    }
  }

  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

 

  .table {
    color: #fff;

    table {
      background: rgba(0, 0, 0, 0.8);
      border-radius: 10px;
      // border-color: #0f0;
      border-collapse: inherit;
      text-align: center;
      th {
        margin-bottom: -2px;
      }
    }


    td {
      position: relative;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    tr:last-child td {
      border-bottom: transparent;
    }

    td {
      .profit-sum {
        color: #16CFA3;
        font-size: 24px;
      }

      

      .table-tooltip {

        display: none;
        background: rgba(0, 0, 0, 0.8);
        padding: 10px;
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border: 1px #fff solid;
        position: absolute;

        bottom: 40px;
        left: 40px;

        div {
          white-space: nowrap;
          text-transform: capitalize;
        }
      }

      &:hover {
        .table-tooltip {
          display: block;
          z-index: 100;
        }
      }
    }
  }

  .button {
    font-size: 18px;
  }


  .summary {

    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: center;

    margin: 35px 0 25px;
    gap: 20px;

    .totals {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: center;
      color: #fff;

    }

    @media only screen and (max-width: 720px) {
      flex-direction: column;
    }

    .summary-list {
      // flex: 1;
      // align-self: stretch;
      display: flex;
      flex-direction: row;
      gap: 20px;
      flex-wrap: wrap;

    }
  }

  .summary-value {
    border: 1px #fff solid;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 4px 15px;
    gap: 9px;

    @media only screen and (max-width: 720px) {
      flex: 26%;
    }

    .name {
      font-family: 'BeVietnam-Regular', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      text-align: center;
      letter-spacing: 0.07em;
      text-transform: capitalize;

      color: #FFFFFF;

      flex: 1;
      text-align: left;
    }

    .value {
      font-family: 'BeVietnam-Medium', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      text-transform: uppercase;

      /* GS_PRIMARY_GREEN */

      color: #16CFA3;
    }
  }
}

.profit-data {
  padding: 0 8px;
  display: flex;
  flex-direction: column;

  div {
    white-space: nowrap;
    text-transform: capitalize;
    text-align: left;
  }
}