@import '../../constants/styles';

@media only screen and (max-width: 600px) {

  #root,
  .app-container {
    height: unset;
  }
}

.color-primary {
  color: $color-primary;
}

.font-normal {
  font-family: 'BeVietnam-Medium', sans-serif;
}

.font-semi {
  font-family: 'BeVietnam-SemiBold', sans-serif;
}

.font-bold {
  font-family: 'BeVietnam-Bold', sans-serif;
}

.font-light {
  font-family: 'BeVietnam-Light', sans-serif;

}

.font-elight {
  font-family: 'BeVietnam-ExtraLight', sans-serif;
}

.sideblock {
  background: #141414;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  width: 490px;

  height: 100vh;
  overflow-y: scroll;
  color: #fff;

  &::-webkit-scrollbar {
    display: none;
  }

  .apps {
    flex-direction: column;

    .apps-header {
      color: #626262;
      text-transform: uppercase;

      &::after,
      &::before {
        background-color: #626262;
        width: 33%;
      }
    }

    .icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .desktop-apps {
      .top-text {
        display: none;
      }

      .bottom-text {
        font-family: 'BeVietnam-Light', sans-serif;
        padding-bottom: 3px;
      }
    }

    .desktop-apps,
    .mobile-apps {
      align-self: stretch;
    }

    .mobile-apps {
      gap: 30px;

      @media only screen and (max-width: 600px) {
        gap: 15px;

        .top-text {
          font-size: 12px;
          padding-bottom: 2px;
        }

        .bottom-text {
          font-size: 15px;
        }
      }
    }

    .app-button {
      background: #212121;
      flex: 1;
      border-color: #4F4F4F;
    }

    .text {
      justify-content: center;


    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-left: -20px;
    margin-top: -50px;
    margin-bottom: 15px;

    .intro-logo {
      // position: absolute;
      // top: 10px;
      // left: 20px;
      width: 155px;

      // @media only screen and (max-width: 600px) {
      //   width: 170px;
      // }
    }

    .back-to-login {
      color: #6A6A6A;
      font-family: "BeVietnam-Light", sans-serif;
      font-size: 13px;

      a {
        color: #fff;
      }
    }
  }

  a {
    color: #fff;
  }

  padding: 80px 40px 40px 40px;

  @media only screen and (max-width: 600px) {
    .text-3xl {
      font-size: 1.7rem;
    }


    .login-social {

      .icon-google,
      .icon-amazon,
      .icon-apple {
        width: 40px;
        height: 50px;
        background-size: 30px 30px;
      }
    }

  }

  .error {

    font-size: 14px;
    margin-bottom: 10px;
    color: $color-salmon;

    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

    .support {
      font-size: 12px;
      // margin-left: 20px;
    }
  }


  @media only screen and (max-width: 500px) {
    padding: 50px 30px 100px 30px;

    width: 100%;
    height: 100vh;


  }

  .confirm-code-inline {
    padding: 0;


    input {

      padding: 7px 17px !important;
      width: 156px;
      text-align: center;
    }

    // h3 {
    //   font-family: 'BeVietnam-Medium', sans-serif;

    // }
  }












  .form {
    display: flex;
    flex-direction: column;
    gap: 13px;

    margin-bottom: 13px;
  }

  .button-wrapper {
    height: 40px;
    border-radius: 5px;
    width: 100%;
  }

  ul.validation {
    margin-top: 10px;
    margin-bottom: 0;
    padding-left: 20px;

    li {
      color: #6C6C6C;
      font-size: 0.75rem;
      position: relative;
      transition: color 0.25s;

      &::before {
        transform: scaleX(-1) rotate(-35deg);
        padding: 0 5px;
        display: block;
        position: absolute;
        left: -20px;
      }

      &.valid {
        &::before {
          content: "L";
          color: $color-primary;
          left: -18px;
        }

        color: $color-primary;
      }

      &.invalid {
        &::before {
          content: "✕";
          transform: none;
          color: #6C6C6C;
        }

        color: #6C6C6C;
      }
    }
  }




  .or-separator {
    text-align: center;
    color: #5C5B5B;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    text-transform: uppercase;

    &:after,
    &:before {
      content: "";
      display: block;
      flex: 1;
      height: 1px;
      background-color: #2E2E2E;
      top: 12px;
    }

    &:after {
      left: 0;
    }

    &:before {
      right: 0;
    }
  }


  .input-wrapper {
    border: none;
    background: none;
    padding: 0;

    input {
      color: #fff;
      background: #1D1D1D;
      width: 100%;
      border-radius: 5px;
      height: 40px;
      padding: 7px 17px;
      font-family: 'BeVietnam-Light', sans-serif;

      &::placeholder {
        color: #808080;
      }
    }
  }



  h1 {}
}

.new-intro-screen {

  .user-not-eligible {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(20, 20, 20, 0.8);
    z-index: 1000000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .user-not-eligible-modal {
      z-index: 2000000;
      padding: 80px 40px;
      background: #141414;
      border: 1px solid #464646;
      border-radius: 10px;
      // max-width: 850px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-family: 'BeVietnam-Light', sans-serif;
        margin: 0 0 50px 0;
        font-size: 30px;
        color: #fff;
        text-align: center;
      }

      .text {
        font-family: 'BeVietnam-Light', sans-serif;
        margin: 0;
        font-size: 22px;
        color: #fff;
        text-align: center;

        .redirect {
          // display: block;
          background: $color-primary;
          padding: 5px 20px 7px;
          text-decoration: none;
          color: #fff;
          border-radius: 5px;
          font-size: 20px;
        }
      }

      @media only screen and (max-width: 600px) {
        h1 {
          font-size: 22px;
        }

        .text {
          font-size: 18px;
        }

      }
    }

  }

  .content-block {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 55px 55px 55px 55px;

    @media only screen and (max-width: 900px) {
      display: none;
    }

    .gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 60%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 71.87%);
      ;
      z-index: 1;
    }

    .top-group {
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .preview {
      width: 80%;
      align-self: center;
    }

    h1 {
      color: #fff;
      font-size: 70px;
    }

    h2 {
      color: #fff;

      font-size: 30px;
    }

    .text {
      z-index: 10;
      // padding-top: 60px;
      color: #fff;

      font-size: 18px;
    }
  }

  .content-block {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 490px);
    height: 100vh;
    z-index: 100;

    // background: #f0f;
  }

  .logout-button {
    position: absolute;
    top: 18px;
    right: 25px;

    .button-wrapper {
      .green-lined {
        border-color: #808080;
        border-width: 0.5px;
        color: #808080;
        padding: 0 15px;
        font-size: 13px;
        font-family: 'BeVietnam-ExtraLight', sans-serif;

      }
    }
  }

  background: url(../../assets/images/new-bg.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 600px) {
    .until {
      font-size: 0.75rem;
    }


  }

  .skeleton {
    animation: skeleton-loading2 1s linear infinite alternate;
  }

  .skeleton-text {
    height: 1.5rem;
  }

  .validation {
    margin-bottom: 13px !important;
  }


  .no-ref-data {
    color: $color-salmon;
  }

  .intro-loader {
    // opacity: 0.5;
    padding: 18px 40px 40px 40px;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000000;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: #141414;

    img {
      width: 80px;
    }
  }

  .payment-plan-header {
    margin-top: 30px;
    padding-bottom: 15px;
    border-bottom: 1px #2E2E2E solid;
  }

  .payment-plan-period {
    margin-top: 13px;

    padding-bottom: 15px;
    border-bottom: 1px #2E2E2E solid;

    .checkbox {
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 33px;
      // background: #0f0;
    }

    .period-row {
      height: 33px;
      line-height: 30px;
      padding-right: 7px;
      cursor: pointer;
      transition: all 0.2s;

      .previous-price {
        color: #ABABAB;
        margin-right: 15px;
        text-decoration-line: line-through;
      }

      border-width: 1px;
      border-color: transparent;
      box-sizing: content-box;

      &.active {
        background: #212121;
        border-color: #2d2d2d;
        border-radius: 2px;
        border-width: 1px;
      }
    }
  }

  .final {
    height: calc(100vh - 150px);

    @media only screen and (max-width: 500px) {
      // background-color: #0f0;
      height: auto;
      padding-bottom: 80px;

    }

    h1 {
      font-family: 'BeVietnam-Light', sans-serif;
      font-size: 18px;
      margin-bottom: 30px;
    }

    h2 {
      font-family: 'BeVietnam-Light', sans-serif;
      font-size: 20px;

    }

    .stores {
      padding-bottom: 50px;

      img {
        height: 55px;
      }
    }

    .desktop-platforms {


      display: flex;
      flex-direction: row;
      gap: 20px;

      .platform-button {
        background: #212121;
        mix-blend-mode: normal;
        border: 1px solid #4F4F4F;
        border-radius: 15px;

        width: 110px;
        height: 90px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        span {
          margin-top: 5px;
          font-size: 17px;
          color: #d3d3d3;
          font-family: 'BeVietnam-Light', sans-serif;
        }
      }

    }

    .download-button-mobile {

      img {
        width: 320px;
      }
    }

    .download-button {

      text-decoration: none;
      margin-top: 50px;
      width: 100%;
      height: 70px;

      background: #212121;
      mix-blend-mode: normal;
      border: 1px solid #4F4F4F;
      border-radius: 5px;

      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      font-family: 'BeVietnam-Light', sans-serif;
      font-size: 22px;

      img {
        height: 40px;
      }
    }

    .sub-button-text {
      font-family: 'BeVietnam-Light', sans-serif;
      font-size: 15px;
      line-height: 109.3%;
      color: #717171;
      margin-top: 10px;
      text-align: center;
    }

    .qr {
      font-size: 20px;
      font-family: 'BeVietnam-Light', sans-serif;
      // background: #00ff0080;

      img {
        width: 120px;
        height: 120px;
        margin: 20px;
      }
    }

    ul {
      padding: 0;
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        font-family: 'BeVietnam-Light', sans-serif;
        font-size: 16px;
        color: #d3d3d3;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &::before {

          content: "";
          background: url(../../assets/images/bullet-wave.svg);
          width: 22px;
          height: 14px;
          margin-right: 11px;
          display: block;
        }
      }
    }

    .or-separator {
      margin: 50px 0;
    }

    .button-wrapper {
      max-width: 324px;
    }


  }

  .payment-plan-totals {
    margin-top: 13px;
    padding-bottom: 15px;
    border-bottom: 1px #2E2E2E solid;
  }



  .discount {
    position: relative;

    &:after,
    &:before {
      display: block;
      position: absolute;
      content: "";
      display: block;
      width: 130%;
      height: 120%;
      border: 1px #2E2E2E solid;
      border-radius: 10px;
    }

    &:after {
      top: -8px;
      right: -14px;
    }

    &:before {
      bottom: -10px;
      left: -14px;
    }
  }



  .login-header {
    // height: 40px;
    align-items: center;

    .login-social {
      margin-bottom: 0;
    }
  }

  .terms {
    margin-top: 13px;
    color: #6A6A6A;

    a {
      color: #6A6A6A;
    }
  }

  .button-wrapper {
    height: 40px;
    // background: #16CFA3;
    border-radius: 5px;
    width: 100%;

    .button {
      color: #fff;
      // padding-bottom: 4px;

    }
  }

  .email-password {
    margin-top: 13px;

    .error {
      color: red;
      font-size: 0.75rem;
    }

    .form {
      display: flex;
      flex-direction: column;
      gap: 13px;

      margin-bottom: 13px;
    }



    .show-pass {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      background: none;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }



  .step-code {
    margin-top: 13px;

    .code-input {
      position: relative;

      input {
        width: 156px;
        text-align: center;
      }

      img {
        position: absolute;
        right: 3px;
        width: 30px;
        top: 5px;
      }
    }

    .error {
      margin: 13px 0;
      gap: 5px;
    }

  }



  .payment {
    position: relative;

    .auth-loading {
      background: #141414;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -100px;
      padding-bottom: 100px;
      z-index: 1000000;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        font-family: 'BeVietnam-Light', sans-serif;
        font-size: 18px;
        text-align: center;
      }

    }


    .sign-up-header {
      position: relative;
      padding: 10px 0;
      border-bottom: 1px #2E2E2E solid;
    }

    .payment-tabs {
      display: flex;
      flex-direction: row;
      gap: 25px;
      position: relative;

      button {
        border: none;
        background: none;
        color: #767575;
        padding: 10px 0;
        font-family: 'BeVietnam-SemiBold', sans-serif;


        &.active {
          color: #fff;
          border-bottom: 1px #fff solid;
        }

      }

      .icons {
        position: absolute;
        right: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .payment-icons {
          display: flex;
          flex-direction: row;
          gap: 13px;
          align-items: center;
          transition: all 0.3s;

          @media only screen and (max-width: 600px) {
            gap: 8px;
          }

          // background: #0f0;
          img {
            padding: 8px 0;
            width: 30px;

            @media only screen and (max-width: 600px) {
              width: 20px;
            }
          }

          .union-icon {
            height: 20px;
            padding: 0;
            width: unset;

            @media only screen and (max-width: 600px) {
              height: 14px;
            }
          }
        }
      }
    }

    .payment-type {
      margin-top: 20px;



    }

    .payment-element {
      height: 40px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }

  }



  .b-contain *,
  .b-contain *::before,
  .b-contain *::after {
    box-sizing: content-box !important;
  }

  .b-contain input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .b-contain span {
    line-height: 1.5;
    font-size: 1rem;
    font-family: inherit;
  }

  .b-contain {
    display: table;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }

  .b-contain input[type="checkbox"]~.b-input {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.25rem;
    width: 1.25rem;
    background: #2E2E2E;
    transition: background 250ms;
    border: 1px solid #2E2E2E;
    border-radius: 0.2rem;
  }

  .b-contain input[type="radio"]~.b-input {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.25rem;
    width: 1.25rem;
    background: #2E2E2E;
    transition: background 250ms;
    border: 1px solid #2E2E2E;
    border-radius: 3rem;
    transform: translateX(-50%) translateY(-50%);
  }

  .b-contain input[type="checkbox"]~.b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 0.3rem;
    height: 0.6rem;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transition: background 250ms;
    transform: rotate(45deg);
  }

  .b-contain input[type="radio"]~.b-input::after {
    content: "";
    position: absolute;
    display: none;
    left: 4px;
    top: 4px;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 3rem;
    background: #ffffff;
    transition: background 250ms;
  }

  .b-contain input[type="checkbox"]:disabled~.b-input::after {
    border-color: #ffffff;
  }

  .b-contain input:checked~.b-input::after {
    display: block;
  }

  .b-contain:hover input[type="checkbox"]:not([disabled])~.b-input,
  .b-contain input[type="checkbox"]:focus~.b-input {
    background: #2E2E2E;
    border-color: #64748b;
  }

  .b-contain:hover input[type="radio"]:not([disabled])~.b-input,
  .b-contain input[type="radio"]:focus~.b-input {
    background: #e2e8f0;
    border-color: #64748b;
  }

  .b-contain input:focus~.b-input {
    box-shadow: 0 0 0 2px #60a5fa;
  }

  .b-contain input[type="checkbox"]:checked~.b-input {
    background: #fff;
    border-color: #2E2E2E;
  }

  .b-contain input[type="radio"]:checked~.b-input {
    background: #2E2E2E;
    border-color: #2E2E2E;
  }

  .b-contain input[type="checkbox"]:disabled~.b-input,
  .b-contain input[type="radio"]:disabled~.b-input {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .b-contain input[type="radio"]:disabled~.b-input::after {
    background: #ffffff;
  }

  .b-contain:hover input[type="checkbox"]:not([disabled]):checked~.b-input,
  .b-contain input[type="checkbox"]:checked:focus~.b-input {
    background: #2E2E2E;
    border-color: #2E2E2E;
  }

  .b-contain:hover input[type="radio"]:not([disabled]):checked~.b-input,
  .b-contain input[type="radio"]:checked:focus~.b-input {
    background: #2563eb;
    border-color: #1e40af;
  }
}

.login-error {
  text-align: center;
  width: 100%;
  color: #FFA2A2;
  font-size: 15px;
  padding: 1em 0;
  // text-transform: uppercase;
  font-family: 'BeVietnam-Light', sans-serif;

}




.intro-screen,
.download-screen {
  // background: url("../../assets/images/bg/login-bg.png") no-repeat center;
  background-size: cover;
  width: 100%;
  padding: 0 20px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.download-screen {

  .intro-logo {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .register-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



    .logo {
      gap: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      @media (max-width: 600px) {
        flex-direction: column;

        img {
          // width: 50px;
        }
      }

    }



  }

  h1 {
    color: #fff;
    margin-bottom: 23px;
    font-family: "PlayfairDisplay-Bold", sans-serif;
    text-align: center;
    white-space: nowrap;

    @media (max-height: 850px) {
      margin-bottom: 10px;
      font-size: 3rem;
    }
  }

  h2 {
    font-family: 'BeVietnam-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 109.3%;
    text-align: center;
    letter-spacing: -0.045em;
    color: #FFFFFF;
    text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5), -3px -3px 10px rgba(0, 0, 0, 0.5);

    @media only screen and (max-width: 600px) {
      font-size: 22px;
    }
  }

  .download-content {
    max-width: 1200px;
    align-self: center;

    h2 {
      margin: 80px auto;
    }

    .comming-soon {
      width: 620px;
      margin: 0 auto;
      margin-top: 60px;

      h2,
      h1 {
        text-align: left;
        margin: 0;
        font-size: 1.5rem;
        white-space: nowrap;
      }

      h2 {
        // font-size: 18px;
        font-family: BeVietnam-Light, sans-serif;
      }

      @media only screen and (max-width: 600px) {
        width: 100%;
        text-align: center;

        h2,
        h1 {
          text-align: center;
          margin: 0;
        }

        h1 {
          margin-bottom: 15px;
        }
      }
    }

    .apps {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 50px;




      .desktop,
      .mobile {
        display: flex;
        flex-direction: column;

        .apps-header {
          position: relative;
          margin-bottom: 13px;

          @media only screen and (max-width: 600px) {
            display: none;
          }

          &:before,
          &:after {
            position: absolute;
            content: "";
            height: 1px;
            width: 30%;
            background-color: #fff;
            top: 50%;
          }

          &:before {
            left: 0;

          }

          &:after {
            right: 0;
          }

          font-family: BeVietnam-Light,
          sans-serif;
          font-style: normal;
          font-size: 20px;
          line-height: 109.3%;
          /* identical to box height, or 22px */

          letter-spacing: -0.015em;
          text-transform: lowercase;

          color: #FFFFFF;
          text-align: center;

        }

      }

      @media only screen and (max-width: 600px) {
        flex-direction: column-reverse;

      }


      .desktop-apps,
      .mobile-apps {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: center;


        @media only screen and (max-width: 600px) {
          flex-direction: column;
          align-items: center;
        }

        .app-button {
          display: flex;
          flex-direction: row;
          gap: 10px;
          min-width: 134px;

          background: #000000;
          border: 1px solid #A6A6A6;
          border-radius: 5px;
          text-decoration: none;
          padding: 10px 15px;

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .text {
            display: flex;
            flex-direction: column;
            text-align: left;

            .top-text {
              font-family: BeVietnam-Light, sans-serif;
              font-style: normal;
              font-weight: 100;
              font-size: 13px;
              // line-height: 19px;
              color: #FFFFFF;
              line-height: 13px;
            }

            .bottom-text {
              font-family: 'BeVietnam-SemiBold', sans-serif;
              font-style: normal;
              font-size: 17px;
              // line-height: 25px;
              line-height: 17px;
              color: #FFFFFF;
            }
          }
        }
      }
    }

  }

}




.intro-content {
  max-width: 1200px;
  padding: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 60%;
  margin: 0 auto;
  align-self: center;
  gap: 40px;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
  }

  h1 {
    color: #fff;
    font-family: 'BeVietnam-SemiBold', sans-serif;
    font-style: normal;

    font-size: 6vh;

    line-height: 109.3%;
    margin-bottom: 5vh;
    /* or 66px */

    letter-spacing: -0.015em;
    text-transform: capitalize;
    text-align: left;

    @media only screen and (max-width: 600px) {
      font-size: 3vh;
      text-align: center;
    }

  }

  .intro-content-left,
  .intro-content-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
  }


  .intro-content-left {
    @media only screen and (max-width: 600px) {

      text-align: center;
      align-items: center;
    }
  }

  .intro-content-right {
    position: relative;

    .intro-img1 {
      position: absolute;

      width: 100%;

      top: 50%;
      left: 50%;
      transform: translateY(-70%) translateX(-50%);

      @media only screen and (max-width: 600px) {
        transform: translateY(-50%) translateX(-50%);
        // width: 50%;
      }
    }

    .intro-img2 {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-30%) translateX(40%);
      z-index: 10;

      @media only screen and (max-width: 600px) {
        display: none;
      }

      // right: -120px;
    }
  }

  p {
    font-family: 'BeVietnam-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    // width: 294px;
    text-align: center;

    color: #FFFFFF;
  }

  .intro-join {
    padding: 6px 30px 8px;

    /* GS_PRIMARY_GREEN */
    font-size: 22px;

    background: #16CFA3;
    border: 1px solid #FFFFFF;
    border-radius: 50px;
    color: #FFFFFF;
    // margin: 0 20px;

    @media only screen and (max-width: 600px) {
      margin: 0;
    }
  }

}

.auth-logo {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

// .or-separator {
//   color:#fff;
//   &:before, &:after {
//     position: absolute;

//     content: "";
//     height: 1px;
//     width: 100%;
//     background-color: #fff;
//   }
// }

.login-screen {
  background: url(../../assets/images/new-bg.jpeg);
  background-size: cover;
  width: 100%;

  height: 100%;
  font-family: 'BeVietnam-Light', sans-serif;

  a,
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .apps {
    .mobile-apps {
      .app-button {
        padding: 10px;
      }
    }
  }

  .login-screen-wrapper {
    max-width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    //background: transparentize($color-secondary, 0.3);
    //border-radius: 5px;
  }

  .login-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //padding-top: 90px;

    h1 {
      margin: 23px 0;
      color: #fff;

      @media (max-height: 720px) {
        margin-bottom: 10px;
        font-size: 2rem;
      }
    }

    h4 {
      width: 300px;
      position: relative;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 50px;
      color: #fff;
      font-family: BeVietnam-Medium, sans-serif;

      @media (max-height: 800px) {
        margin-bottom: 20px;
      }

      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:before,
      &:after {
        content: '';
        width: 40%;
        height: 2px;
        background: transparentize(#fff, 0);
        // position: absolute;
        // left: 0;
        // top: 14px;
      }

      &:after {
        left: auto;
        right: 0;
      }
    }
  }

  .login-content {
    padding-top: 14px;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 100%;
  }

  .login-or-create {
    font-size: 15px;
    line-height: 23px;
    margin-top: 25px;
    color: #ababab;
    text-align: center;

    a {
      color: #fff;
    }

    @media (max-height: 720px) {
      margin-bottom: 15px;
    }
  }

  .login-form {
    align-self: stretch;

    input {
      padding-left: 50px;
      // padding-bottom: 10px;
    }
  }





  .login-form-row {
    margin-bottom: 26px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .input-wrapper {
      // max-width: 350px;
      // align-self: stretch;
      width: 100%;
    }

    input {
      font-family: 'BeVietnam-Light', sans-serif;
      outline: 0;
      width: 100%;
      height: 60px;
      background: #fff;
      border: 1px solid #e5e5e580;
      border-radius: 50px;
      padding-left: 50px;
      color: $color-dark;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.07em;

      @media (max-height: 850px) {
        height: 48px;
        font-size: 14px;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $color-dark;
        opacity: 1;
        /* Firefox */
      }
    }

    .error {
      input {
        background: #FFA2A2;
      }
    }
  }

  .login-forgot-pass {
    text-align: left;
    margin-bottom: 18px;
    margin-top: -10px;
    color: #ABABAB;
    // max-width: 350px;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
    // .input-wrapper {
    //   // align-self: stretch;
    //   width: 100%;
    // }

    a {
      font-size: 15px;
      line-height: 22px;
      text-decoration: underline;
      color: #fff;
      text-transform: capitalize;
    }
  }

  .form-error-text {
    font-size: 0.65em;
    font-family: 'BeVietnam-Light', sans-serif;
    color: #fff;
    position: absolute;
    bottom: -15px;
    margin-bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .login-remember {
    text-align: left;

    .cs-checkbox {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      font-size: 15px;
      line-height: 22px;
      color: #FFFAE2;

      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: transparent;
        border: 1px solid #FFFAE2;
        border-radius: 3px;
        transition: all .25s ease-in-out;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      &:hover input~.checkmark {
        background-color: rgba(255, 250, 226, 0.25);
      }

      input:checked~.checkmark {
        //background-color: #2196F3;
      }

      input:checked~.checkmark:after {
        display: block;
      }

      .checkmark:after {
        left: 8px;
        top: 4px;
        width: 7px;
        height: 12px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .login-form-submit {

    .circle-button {

      @media (max-height: 720px) {
        width: 90px;
        height: 90px;
      }

      border: 1px solid #fff;

      &:before {
        background-color: #fff;
      }
    }
  }


  .status {
    font-size: 12px;
    color: $color-secondary;
  }

  .resend {
    .button {
      font-size: 12px;
      color: $color-secondary !important;
    }
  }

  .register-success {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }

    background: transparentize($color-secondary, 0.4);
    padding: 20px 60px;
    border-radius: 5px;
    align-self: center;

    a.app-btn-primary {
      display: inline-block;
      text-decoration: none;
      font-family: BeVietnam-Medium, sans-serif;
      letter-spacing: 0.07em;
      width: 180px;
      margin-top: 20px;
      text-transform: uppercase;
      height: 35px;
      font-size: 18px;
      line-height: 32px;
      border-radius: 5px;

      &:hover {
        color: #31394F;
      }
    }

    .status {
      font-family: BeVietnam-SemiBold, sans-serif;
      text-transform: capitalize;
      animation: blink-animation 2s linear infinite;
      -webkit-animation: blink-animation 2s linear infinite;
      color: $color-dark;
      font-size: 14px;
    }



    input[type="text"] {
      outline: 0;
      width: 100%;
      height: 60px;
      background: #fff;
      border: 1px solid rgba(50, 57, 79, 0.7490196078);
      font-family: BeVietnam-SemiBold, sans-serif;
      border-radius: 5px;
      color: rgba(50, 57, 79);
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.07em;
      text-align: center;
      // padding-left: 32px;

      @media (max-height: 850px) {
        height: 48px;
        font-size: 14px;
      }

      &:disabled {
        opacity: 0.5;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(50, 57, 79, 0.7490196078);
        font-family: BeVietnam-Light, sans-serif;
        opacity: 1;
        /* Firefox */
      }
    }




    .button {
      font-family: BeVietnam-Regular, sans-serif;
      letter-spacing: 0.07em;
      font-size: 16px;
      text-transform: uppercase;
      padding: 6px;

      &:disabled {
        opacity: 0.5;
      }

    }

    .confirm-code {
      margin: 15px 0;
    }

    .send-again {
      .button {
        font-size: 12px;


        &:disabled {
          opacity: 0.5;
        }

      }
    }
  }
}