.login-social,
.register-social {
  max-width: 250px;

  @media (max-height: 800px) {
    // margin-bottom: 15px;
  }

  @media (max-height: 750px) {
    margin-bottom: 0px;
  }

  .social-btn {
    background: none;
    // background: #00ff0080;
    padding: 0;
    border: none;
    outline: 0;
    // margin-left: 18px;
    // margin-right: 18px;
    border-radius: 100%;
    padding: 10px;

    img {
      width: 35px;
      height: 35px;
    }

  }

  .icon-google, .icon-amazon, .icon-apple {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
  }

  .icon-google {
    background-image: url(../../assets/images/icon-google-color.svg);

  }
  .icon-apple {
    background-image: url(../../assets/images/icon-apple-color.svg);
    margin-top: -4px;
  }
  .icon-amazon {
    background-image: url(../../assets/images/icon-amazon-color.svg);
    background-size: 40px 40px;
  }
  
}