@import '../../constants/styles';

.button-wrapper {
  position: relative;
  overflow: hidden;
  padding: 2px;
  border-radius: 6px;

  &.round {
    border-radius: 50%;
  }

  .loading-border {
    height: 100%;
  }

  .button {
    width: 100%;
    height: 100%;
    position: relative;
    outline: none !important;
    background: none;
    border: none;
    border-radius: 5px;
    // padding: 10px;
    z-index: 10;
    overflow: hidden;

    .gradient {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      background: linear-gradient(0deg, rgba(0,0,0,0.5) -150%, rgba(0,0,0,0) 100%);
    }

    transition: all 0.25s linear;

    &.white {
      background: #fff;
      .button {
        color: $color-primary;
      }
    }

    &.light {
      background: $color-secondary;
      color: $color-primary-text;

      &:hover {
        background: $color-secondary-hover;
      }
    }

    &.green {
      background: $color-primary;
      color: #fff;

      &:hover {
        background: $color-primary-hover;
      }
    }
    &.green-lined {
      border: 1px $color-primary solid;
      color: $color-primary;
      background: none;
      &:hover {
        background: none;
      }
    }

    &.danger {
      background: red;
      color: #fff;

      &:hover {
        background: red;
      }
    }

    &.dark {
      background: $color-dark;
      color: $color-secondary-text;

      &:hover {
        background: lighten($color-dark, 5%);
      }
    }

    &:disabled, &[disabled], &:disabled:hover {
      background-color: #cccccc;
      color: #666666;
    }

    &.link {
      background: none;
      text-decoration: underline;
      text-transform: lowercase;
      color: $color-dark;
      font-family: BeVietnam-Light, sans-serif;
      &:hover {
        color: lighten($color-dark, 5%);
      }
    }

  }
  &.is-active {
    .loading-animation {
      content: "";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500px;
      height: 4px;
      z-index: 0;
      transform-origin: 0 50%;
      animation: bullet 3s linear infinite forwards;
      background: $color-primary;
      box-shadow: 0 0 4px 8px $color-primary;
    }
  }
}

@keyframes bullet {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spring {
  0% {
    transform: translateX(-170%) ;
  }
  50% {
    transform: translateX(100%) ;
  }
  100% {
    transform: translateX(-150%) ;
  }
}


@keyframes horizontal-slide {
  from {
    transform: translateX(-150px) translateY(-50%) rotate(30deg);
  }
  to {
    transform: translateX(600px) translateY(-50%) rotate(30deg);
  }
}
