@import '../../constants/styles';

.confirm-code-inline {
  color: #6A6A6A;

  .code-input {
    position: relative;

    input {
      width: 156px;
      text-align: center;
      @media (max-width: 600px) {
        width: 150px;
      }
    }

    img {
      position: absolute;
      right: 3px;
      width: 30px;
      top: 5px;
    }
  }

  .verification-label {
    height: 40px;
    color: #fff;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
}

.confirm-code {

  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);

  padding: 20px 50px;
  border-radius: 5px;
  align-self: center;
  position: relative;

  h3 {
    font-family: 'PlayfairDisplay-SemiBold', sans-serif;
    margin-bottom: 50px;
  }

  .email {
    margin-bottom: 30px;
  }

  .error {
    margin: 20px 0 30px;
    font-size: 13px;
    opacity: 0;
    height: 24px;

    &.active {
      opacity: 1;
    }
  }

  .code-input {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .separator {
      text-align: center;
      font-size: 36px;
      width: 42px;
      height: 63px;
    }

    input {
      width: 42px;
      height: 63px;
      background: rgba(255, 255, 255, 0.4);
      border: 1px solid #31394F;
      text-align: center;
      font-size: 36px;
      transition: outline 0.25s linear;

      &:nth-child(4n+1) {
        border-radius: 5px 0 0 5px;
      }

      &:nth-child(4n+3) {
        border-radius: 0 5px 5px 0;
      }

      &:nth-child(2n) {
        margin: 0 -1px;
      }

      outline: 0px solid #32a1ce !important;

      &:focus {
        outline: 3px solid #32a1ce !important;
      }
    }
  }

  .support {
    margin: 30px auto;
    max-width: 320px;

    a {
      color: $color-dark;
    }
  }

  .go-back {
    position: absolute;
    bottom: 15px;
    left: 20px;

    .button-wrapper {
      margin: 0;
    }
  }

  .send-again {
    position: absolute;
    right: 32px;
    bottom: 203px;
    width: 110px;

    @media (max-width: 600px) {
      bottom: 260px;
      right: 10px;
    }

    .button-wrapper {
      margin: 0;
    }

    .button {
      height: 50px;
      font-size: 13px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }
}