.input-wrapper {
  position: relative;
  flex: 1;

  // max-width: 416px;
  height: 38px;

  background: rgba(0, 0, 0, 0.3);
  border: 1px solid #FFFFFF;
  border-radius: 5px;
  // overflow: hidden;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;

  .label {
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: -20px;
    left: 0;
  }

  .icon {
    font-size: 25px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-right: 17px;
  }

  input, textarea {
    width: 100%;
    background: none;
    height: 100%;
    border: none;
    color: #fff;
    outline: none;

    font-family: 'BeVietnam-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      opacity: 0.7; /* Firefox */
    }

    // background: #f00;
  }

  button {
    position: absolute;
    right: 26px;
    font-size: 25px;
    top: 50%;
    transform: translateY(-50%);

    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: #fff;

    background: none;

    border: none;
    position: absolute;
    align-self: flex-end;
  }

}