// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '~pure-react-carousel/dist/react-carousel.es.css';
@import 'constants/styles';
@import 'assets/styles/animations';

/* FONTS */
@font-face {
  font-family: 'BeVietnam-ExtraLight';
  src: url('assets/fonts/BeVietnam-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-Light';
  src: url('assets/fonts/BeVietnam-Light.eot');
  src: url('assets/fonts/BeVietnam-Light.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Light.svg#BeVietnam-Light') format('svg'),
    url('assets/fonts/BeVietnam-Light.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Light.woff') format('woff'),
    url('assets/fonts/BeVietnam-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-Regular';
  src: url('assets/fonts/BeVietnam-Regular.eot');
  src: url('assets/fonts/BeVietnam-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Regular.svg#BeVietnam-Regular') format('svg'),
    url('assets/fonts/BeVietnam-Regular.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Regular.woff') format('woff'),
    url('assets/fonts/BeVietnam-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-Medium';
  src: url('assets/fonts/BeVietnam-Medium.eot');
  src: url('assets/fonts/BeVietnam-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Medium.svg#BeVietnam-Medium') format('svg'),
    url('assets/fonts/BeVietnam-Medium.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Medium.woff') format('woff'),
    url('assets/fonts/BeVietnam-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-SemiBold';
  src: url('assets/fonts/BeVietnam-SemiBold.eot');
  src: url('assets/fonts/BeVietnam-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-SemiBold.svg#BeVietnam-SemiBold') format('svg'),
    url('assets/fonts/BeVietnam-SemiBold.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-SemiBold.woff') format('woff'),
    url('assets/fonts/BeVietnam-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BeVietnam-Bold';
  src: url('assets/fonts/BeVietnam-Bold.eot');
  src: url('assets/fonts/BeVietnam-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/BeVietnam-Bold.svg#BeVietnam-Bold') format('svg'),
    url('assets/fonts/BeVietnam-Bold.ttf') format('truetype'),
    url('assets/fonts/BeVietnam-Bold.woff') format('woff'),
    url('assets/fonts/BeVietnam-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-SemiBold';
  src: url('assets/fonts/PlayfairDisplay-SemiBold.eot');
  src: url('assets/fonts/PlayfairDisplay-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/PlayfairDisplay-SemiBold.svg#PlayfairDisplay-SemiBold') format('svg'),
    url('assets/fonts/PlayfairDisplay-SemiBold.ttf') format('truetype'),
    url('assets/fonts/PlayfairDisplay-SemiBold.woff') format('woff'),
    url('assets/fonts/PlayfairDisplay-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PlayfairDisplay-Bold';
  src: url('assets/fonts/PlayfairDisplay-Bold.eot');
  src: url('assets/fonts/PlayfairDisplay-Bold.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/PlayfairDisplay-Bold.svg#PlayfairDisplay-Bold') format('svg'),
    url('assets/fonts/PlayfairDisplay-Bold.ttf') format('truetype'),
    url('assets/fonts/PlayfairDisplay-Bold.woff') format('woff'),
    url('assets/fonts/PlayfairDisplay-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.text-green {
  color: $color-primary;
}

/* GLOBAL STYLES */
html,
body {
  font-size: 100%;
  // user-select: none;
  // -webkit-user-select: none;
}

* {
  outline: none !important;
}


.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}


body {
  font-family: 'BeVietnam-Regular', sans-serif;
  background: #141414; //#141414
  animation: fadein .3s linear forwards;
  // background-image: url(./assets/images/envs/forest.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#root {
  position: relative;
  min-height: 100vh;
  //background: url("./assets/images/app-bg.jpg") no-repeat center;
  background-size: cover;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-primary-text;
}

/* TITLES */
.title-h1 {
  font-family: 'PlayfairDisplay-SemiBold', sans-serif;
  font-size: 3rem;
  line-height: 109.3%;
  letter-spacing: -0.045em;
  color: $color-primary-text;
  margin-bottom: 30px;
  text-transform: capitalize;
}

.title-h1-lg {
  font-family: 'PlayfairDisplay-SemiBold', sans-serif;
  font-size: 3rem;
  line-height: 109.3%;
  letter-spacing: -0.045em;
  color: $color-primary-text;
  text-transform: capitalize;

  @media (max-height: 800px) {
    font-size: 3rem;
  }
}

.title-h1-sm {
  font-family: 'PlayfairDisplay-Bold', sans-serif;
  font-size: 2rem;
  line-height: 109.3%;
  letter-spacing: -0.045em;
  color: $color-primary-text;
  text-transform: capitalize;
}

.title-h2 {
  font-family: 'PlayfairDisplay-SemiBold', sans-serif;
  font-size: 2rem;
  line-height: 101.3%;
  color: $color-secondary-text;
  text-align: center;
  letter-spacing: -0.04em;
  text-transform: capitalize;
  margin-bottom: 3px;
}

/* PARAGRAPHS */
.p-light {
  font-family: 'BeVietnam-Light', sans-serif;
  font-size: 1rem;
  line-height: 101.3%;
  letter-spacing: -0.04em;
  color: $color-secondary-text;
}

.p-regular {}

/* BUTTONS */
.app-btn-primary {
  background-color: $color-primary;
  border-radius: 5px;
  transition: background-color .25s ease-in-out;
  color: $color-primary-text;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-primary-hover;
  }

  &:active {
    border: 1px solid $color-primary-text;
  }
}

.app-btn-secondary {
  background-color: $color-secondary;
  border-radius: 5px;
  transition: background-color .25s ease-in-out;
  color: $color-primary-text;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-secondary-hover;
  }

  &:active {
    border: 1px solid $color-primary-text;
  }
}

.app-btn-dark {
  background-color: $color-dark;
  border-radius: 5px;
  transition: background-color .25s ease-in-out;
  color: $color-secondary-text !important;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-dark-hover
  }

  &:active {
    border: 1px solid $color-primary-text;
  }
}

.app-btn-outline-sm {
  border: 1px solid #FFFAE2;
  border-radius: 5px;
  background-color: transparent;
  color: $color-secondary-text;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color .25s ease-in-out;
  font-size: 11px;
  line-height: 1px;
  letter-spacing: 0.07em;
  text-transform: lowercase;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  span {
    margin-top: -2px;
  }

  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
}

.video-animation {
  z-index: 30;
  position: absolute;
  width: 105%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s linear;

  &.show {
    opacity: 1;
  }
}

.app-menu-btn,
.menu-close-btn,
.home-option-controls {
  .loading {
    animation-name: rotate;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  svg {
    cursor: pointer;

    path {
      transition: all .25s ease-in-out;
    }

    &:hover {
      path {
        fill: #198754;
      }
    }
  }
}

/* CONTAINERS */
.app-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &.fadeout {
    animation: fadeout .3s linear forwards;
  }
}

/* max-width constrains the width of our carousel to 400, but shrinks on small devices */
.carousel__container {
  max-width: 400px;
  margin: auto;
  outline: none !important;
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 25%;
  padding-right: 25%;
  outline: none !important;
  overflow: unset;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  transition: all .25s ease-in-out;
  outline: 0;
}

.carousel__slide--hidden {
  .carousel__inner-slide {
    width: calc(100% - 60px);
    height: 100%;
    left: 30px;
    top: 30px;
    background-color: transparent;
  }
}

.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

/* FORM ERROR */
.form-error-text {
  width: 100%;
  font-size: 0.8em;
  font-family: 'BeVietnam-Light', sans-serif;
  color: $color-salmon;
  // position: absolute;
  // bottom: -15px;
  margin-bottom: 0;
  // left: 50%;
  // transform: translateX(-50%);

  // @media (max-width:444px) {
  //   bottom: -30px;
  // }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.input-file-wrapper {
  background: none;
  border: none;
  padding: 0;
  position: relative;

  .file-error {
    position: absolute;
  }
}


#modal-root {
  z-index: 100000;
}

.app-update {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 430px;
  background: #000000cc;
  margin: 0 auto;
  color: #FFFAE2;
  padding: 0.5rem 2rem;
  border-radius: 5px;
  z-index: 10000000;
  text-align: center;

  .progressbar {
    height: 3px;
    background-color: $color-primary;
  }

  .update {
    margin-top: 15px;
  }

  .button-wrapper {
    margin: 10px auto;
    width: 150px;
    height: 38px;

    button {
      text-transform: capitalize;
    }
  }
}

.table {

  flex: 1;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  position: relative;
  min-height: 500px;
  border-radius: 5px;

  &::-webkit-scrollbar {
    display: none;
  }

  table {
    @media only screen and (max-width: 720px) {
      position: absolute;
      font-size: 12px;
    }

    .sales {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 6px;

    }

    .copy {
      border: none;
      background: none;
      padding: 0;
      color: #fff;
      cursor: pointer;
    }

    .info {

      position: relative;
      cursor: pointer;
      .tooltip {
        position: absolute;
        padding: 8px;
        background-color: #ffffffdd;
        display: none;
        color: #000;
        border-radius: 5px;
        bottom: 20px;
        div {
          white-space: nowrap;
        }
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }

    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;

    // max-width: 1085px;

    font-family: 'BeVietnam-Light',sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 23px;
    margin: 0 auto;
    text-align: center;

    border: 1px #ffffff66 solid;

    thead {
      th {
        border-bottom: 1px #fff solid;
      }
    }

    th {
      color: #16CFA3;
      font-family: 'BeVietnam-Light', sans-serif;
      font-style: normal;
      font-size: 16px;
      line-height: 23px;

      text-transform: uppercase;
      padding: 16px 6px;

      border-right: 1px #fff solid;

      @media only screen and (max-width: 720px) {
        font-size: 12px;
      }

      &:last-child {
        border-right: none;
      }
    }

    td,
    th {
      padding: 16px 6px;
      border-right: 1px #fff solid;

      &.center {
        text-align: center;
      }

      &:last-child {
        border-right: none;
      }
    }
  }



  .status {
    text-transform: uppercase;

    &.paid {
      color: #16CFA3;
    }

    &.unpaid {
      color: #FFA2A2;
    }
  }
}


.loading {
  position: absolute;
  top: 0;
  left: 0;
  background: #000000;
  width: 100%;
  z-index: 10000;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .spinner {
    width: 100px;
    height: 100px;
    border: 5px solid #fff;
    border-radius: 50%;
    border-top-color: #000;
    animation: spin 1s ease-in-out infinite;
  }
}