.affiliate-admin-screen {
  background: url(../assets/images/new-bg.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .header {
    align-self: stretch;
    position: relative;
    height: 80px;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00000080;
    z-index: 10000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .popup {
      padding: 20px;
      border-radius: 10px;
      background: #000;
      color: #fff;
      width: 500px;
      max-width: 100%;
      position: relative;

      display: flex;
      flex-direction: column;

      .message {
        margin-bottom: 20px;
        text-transform: capitalize;
        font-size: 24px;
        text-align: center;
      }

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;

      }
    }

  }

  @media only screen and (max-width: 720px) {

    width: 100%;
    padding: 0 15px;
    overflow-y: scroll;
  }

  .content {
    max-width: 1200px;

    @media only screen and (max-width: 720px) {
      align-self: stretch;
      width: 100%;
    }
  }

  .intro-logo {
    // position: absolute;
    width: 150px;
    // top: 20px;
    // left: 20px;
    
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    background: #00000080;
    width: 100%;
    z-index: 10000;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .spinner {
      width: 100px;
      height: 100px;
      border: 5px solid #fff;
      border-radius: 50%;
      border-top-color: #000;
      animation: spin 1s ease-in-out infinite;
    }
  }

  h1 {
    font-family: "PlayfairDisplay-SemiBold", sans-serif;
    font-size: 2rem;
    color: #fff;
    margin: 0;
    margin-bottom: 2rem;
  }
  
  textarea {
    padding-top: 10px;
  }

  select {
    width: 100%;
    background: none;
    height: 100%;
    border: none;
    color: #fff;
    outline: none;

    font-family: 'BeVietnam-Regular', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #fff;
      opacity: 0.7; /* Firefox */
    }

    // background: #f00;
  }

  .affiliate-list {
    min-width: 800px;
    @media only screen and (max-width: 720px) {
      min-width: unset;
      flex: 1;
      // align-self: stretch;
      width: 100%;
      margin-top: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .head {
    h1 {
      margin: 0;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @media only screen and (max-width: 720px) {
      flex-direction: row;
      align-items: flex-start;
    }

    .button-wrapper {
      max-width: 250px;
    }
  }
  

  .affiliate-form {
    position: relative;
    background: #00000080;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 720px) {
      width: 100%;
      margin-top: 50px;
      align-self: flex-start;
    }

    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
    }

    .input-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 35px;
      gap: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      @media only screen and (max-width: 720px) {
        flex-direction: column;
        gap: 30px;

        input, select {
          padding-top: 6px;
          padding-bottom: 6px;
          font-size: 16px;
        }
      }

    }
  }

  .error {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }

  .table {
    color: #fff;

    flex: 1;
    overflow-y: visible;
    overflow-x: unset;

    td {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
    }
  }

  .button {
    padding: 6px;
    font-size: 15px;
  }

  .separator {
    width: 100%;
    height: 1px;
    color: #808080;
    margin: 20px 0;
  }
}

