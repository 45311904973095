@import '../../../constants/styles';
@import '../../../components/checkbox/checkbox';

.signup-error {
  text-align: center;
  width: 100%;
  color: $color-salmon;
  font-size: 15px;
  margin: 13px 0;
}

.register-screen {
  background: url(../../../assets/images/new-bg.jpeg);
  background-size: cover;
  width: 100%;
  width: 100%;
  height: 100%;
  font-family: 'BeVietnam-Light', sans-serif;

  .code-input {
    input {
      background: #1D1D1D;
      color: #fff;
      &::placeholder {
        color: #808080;
      }
    }
  }

  .validation {
    margin-top: 0 !important;
  }

  a,
  a:not([href]):not([class]),
  a:not([href]):not([class]):hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .password-format-text {
    font-size: 0.65em;
    font-family: 'BeVietnam-Light', sans-serif;
    padding-top: 0;
    color: #fff;
    margin-top: -2em;
    margin-bottom: 2em;
  }

  .register-screen-wrapper {
    max-width: 800px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .register-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      gap: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }


    .inline-logo {
      width: 45px;
      height: 45px;
    }

    @media (max-height: 850px) {
      padding-top: 15px;
    }

    h4 {
      width: 300px;
      position: relative;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 20px;
      color: #fff;
      font-family: BeVietnam-Medium, sans-serif;

      @media (max-height: 800px) {
        margin-bottom: 20px;
      }

      text-transform: uppercase;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      &:before,
      &:after {
        content: '';
        width: 40%;
        height: 2px;
        background: transparentize(#fff, 0);
        // position: absolute;
        // left: 0;
        // top: 14px;
      }

      &:after {
        left: auto;
        right: 0;
      }
    }


    h1 {
      color: #fff;
      margin-bottom: 10px;
      font-family: "PlayfairDisplay-Bold", sans-serif;
      white-space: nowrap;

      @media (max-height: 850px) {
        margin-bottom: 10px;
        font-size: 3rem;
      }
    }
  }

  .register-form {
    form {

      .register-inputs {
        display: flex;
        flex-direction: column;

        @media (max-height: 850px) {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 10px;
          margin-bottom: 15px;

          .register-form-row {
            flex: 50% 1 0;
            margin-bottom: 10px;
            gap: 0px;
          }
        }
      }
    }

    input {
      padding-left: 50px;
      padding-bottom: 7px;
    }
  }

  .register-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    border-radius: 15px;
    padding-top: 14px;
  }

  .register-or-create {
    font-size: 16px;
    line-height: 23px;
    font-family: BeVietnam-Medium, sans-serif;
    margin-bottom: 37px;

    @media (max-height: 850px) {
      margin-bottom: 15px;
    }

    a {
      color: #31394F;
    }
  }

  .form-error-text {
    bottom: -20px;
  }

  .back-to-login {
    text-align: center;
    margin-top: 25px;
    color: #ababab;
    font-size: 15px;

    @media (max-height: 750px) {
      margin-top: 15px;
    }

    a {
      font-size: 15px;
      line-height: 22px;
      text-decoration: underline;
      color: #fff;
      text-transform: capitalize;
    }
  }

  .terms {
    margin: 15px 0;
    color: #6a6a6a;
    font-size: 12px;

    a {
      color: #6a6a6a;
    }

    .form-error-text {
      bottom: -20px;
    }

    .checkbox {
      color: #fff;

      input {
        background-color: transparent;
        border: 1px $color-primary solid;
        color: #fff;
        border-radius: 25px;
        padding: 0;

        margin: 0 12px 0 19px;

        &:before {
          box-shadow: inset 1em 1em #fff;
        }

        &:checked {
          background-color: $color-primary;
        }
      }

      input.error {
        border: 1px #FFA2A2 solid;
        background: transparent;
      }
    }

    label {
      font-size: 15px;

      color: #ababab;

      a {
        color: #fff;
      }
    }
  }

  .register-form-content {
    position: relative;
    align-self: stretch;
    align-items: center;
    padding: 0 20px;
    max-width: 420px;



    .register-form-row {
      position: relative;
      margin-bottom: 26px;

      @media (max-height: 850px) {
        margin-bottom: 20px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .input-wrapper {
        // max-width: 350px;
        // align-self: stretch;
        width: 100%;
      }

      & input[type="text"],
      input[type="password"],
      input[type="email"] {
        font-family: 'BeVietnam-Light', sans-serif;
        outline: 0;
        width: 100%;
        height: 60px;
        background: #fff;
        border: 1px solid #e5e5e580;
        border-radius: 50px;
        padding-left: 50px;
        color: $color-dark;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.07em;

        @media (max-height: 850px) {
          height: 48px;
          font-size: 14px;
        }

        @media (max-height: 700px) {
          height: 40px;
          font-size: 14px;
        }

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: $color-dark;
          opacity: 1;
          /* Firefox */
        }
      }

      .error {
        input {
          background: #FFA2A2;
        }
      }

      label {
        @media (max-height: 850px) {
          //height: 48px;
          line-height: 20px;
          font-size: 14px;
        }
      }

    }



    .register-form-submit {
      padding-top: 25px;

      @media (max-height: 720px) {
        padding-top: 10px;
      }

      .from-intro-submit {
        width: 242px;
        height: 50px;

        /* GS_PRIMARY_GREEN */
        font-family: BeVietnam-Medium, sans-serif;
        background: #16CFA3;
        border: 1px solid #FFFFFF;
        border-radius: 50px;
        color: #fff;
        font-size: 25px;
      }

      .circle-button {

        @media (max-height: 720px) {
          width: 90px;
          height: 90px;
        }

        border: 1px solid #fff;

        &:before {
          background-color: #fff;
        }
      }

      // .register-submit-btn {
      //   width: 97px;
      //   height: 97px;
      //   background: #fff;
      //   border-radius: 100%;
      //   border: none;
      //   position: relative;

      //   &:before {
      //     content: '';
      //     display: block;
      //     width: 97px;
      //     height: 97px;
      //     border-radius: 100%;
      //     border: 1px solid #fff;
      //     position: absolute;
      //     top: 50%;
      //     left: 50%;
      //     transform: translateY(-50%) translateX(-50%) scale(120%);
      //     transition: all .25s ease-in-out;
      //   }

      //   &:hover {
      //     &:before {
      //       transform: translateY(-50%) translateX(-50%) scale(107%);
      //     }
      //   }

      //   &:disabled {
      //     background: #a4a4a4;

      //     &:before {
      //       border: 1px solid #a4a4a4;
      //     }
      //   }
      // }
    }
  }

  .register-success {
    h3 {
      font-size: 20px;
      margin-bottom: 20px;
    }

    background: transparentize($color-secondary, 0.4);
    padding: 20px 60px;
    border-radius: 5px;
    align-self: center;

    a.app-btn-primary {
      display: inline-block;
      text-decoration: none;
      font-family: BeVietnam-Medium, sans-serif;
      letter-spacing: 0.07em;
      width: 180px;
      margin-top: 20px;
      text-transform: uppercase;
      height: 35px;
      font-size: 18px;
      line-height: 32px;
      border-radius: 5px;

      &:hover {
        color: #31394F;
      }
    }

    .status {
      font-family: BeVietnam-Regular, sans-serif;
      font-size: 0.8rem;
      text-transform: capitalize;
      animation: blink-animation 2s linear infinite;
      -webkit-animation: blink-animation 2s linear infinite;
    }

    input[type="text"] {
      outline: 0;
      width: 100%;
      height: 60px;
      background: #fff;
      border: 1px solid rgba(50, 57, 79, 0.7490196078);
      font-family: BeVietnam-SemiBold, sans-serif;
      border-radius: 5px;
      color: rgba(50, 57, 79);
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.07em;
      text-align: center;
      // padding-left: 32px;

      @media (max-height: 850px) {
        height: 48px;
        font-size: 14px;
      }

      &:disabled {
        opacity: 0.5;
      }

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(50, 57, 79, 0.7490196078);
        font-family: BeVietnam-Light, sans-serif;
        opacity: 1;
        /* Firefox */
      }
    }

    .button {
      font-family: BeVietnam-Regular, sans-serif;
      letter-spacing: 0.07em;
      font-size: 16px;
      text-transform: uppercase;
      padding: 6px;

      &:disabled {
        opacity: 0.5;
      }

    }

    .confirm-code {
      margin: 15px 0;
    }

    .send-again {
      .button {
        font-size: 12px;


        &:disabled {
          opacity: 0.5;
        }

      }
    }
  }


  .button-wrapper {
    margin-bottom: 10px;
  }
}