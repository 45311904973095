@import '../../../constants/styles';

.input-wrapper {
  position: relative;
  flex: 1;

  .eye {
    border: none;
    background: none;
    height: 43%;
    aspect-ratio: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    // background: url(../../../assets/images/icon-eye.svg) no-repeat;
    background-size: contain;

    &.active {
      // background: url(../../../assets/images/icon-eye-slash.svg) no-repeat;
      background-size: contain;
    }
  }

  input {

    &:placeholder {
      color: $color-dark;
    }
  }

  .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
